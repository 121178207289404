// 'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider",
        function($stateProvider) {
            $stateProvider
                .state('login', {
                    url: '/?contact_us',
                    templateUrl: 'app/account/login/login.html',
                    controller: ['$controller', '$state', '$scope', function($controller, $state, $scope) {
                        if (typeof config == "undefined") {
                            var LoginCtrlViewModel = $scope; //You need to supply a scope while instantiating.
                            //Provide the scope, you can also do $scope.$new(true) in order to create an isolated scope.
                            return $controller('LoginCtrl', { $scope: LoginCtrlViewModel });
                        } else {
                            $state.go('index.home');
                        }
                    }]
                });
        }
    ]);